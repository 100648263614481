import axios from 'axios'
import router from '@/router'
import { Message } from 'element-ui'
import { getToken, removeToken, getVersionMsg, removeVersionMsg } from './auth'
import Vue from 'vue'

const TOKEN_INVALID = 'Token认证失败，请重新登录'
const NETWORK_ERROR = '网络请求异常，请稍后重试'

function request(requestMethod, url, data, erp) {
  // 获取本地token
  const token = sessionStorage.getItem('tokenForOP')
  // 配置请求头
  const config = {
    headers: {}, // 请求头
    cancelToken: new axios.CancelToken(function(cancel) {
      Vue.Cancel && Vue.Cancel.push(cancel)
    })
  }
  if (token) config.headers['token'] = token

  config.method = requestMethod // 请求方式

  // 根据请求方式的传参key值进行变化
  if (requestMethod === 'get' && data || requestMethod === 'delete' && data) {
    config.params = data
  } else if (requestMethod === 'post' || requestMethod === 'put') {
    config.data = data
  }

  // 特殊处理  接口 delete 用的formData WSZDFL ————————Start————————
  if(url.endsWith('tenantManage/deleteOne')){
    config.params = null
    config.data = data
  }
  // 特殊处理  接口 delete 用的formData WSZDFL ————————End————————

  config.url = process.env.VUE_APP_OPERATIONAL_BACK_OFFICE_API + url // url = base url + request url

  return new Promise((resolve, reject) => {
    axios(config)
      .then(res => {
        if (res.data.errorCode === 0) {
          resolve(res.data.result)
        } else {
          Message.error(Array.isArray(res.data.result) ? res.data.result.join(',') : res.data.result || res.data.errorMsg)
          resolve(false)
        }
      })
      .catch((err) => {
        if (err.message !== 'cancel') {
          Message.error(NETWORK_ERROR)
        }
        resolve(false)
      })
  })
}

function exportRequest(requestMethod, url, data, erp) {
  // 获取本地token
  const token = getToken()
  // 获取本地版本信息
  const version = getVersionMsg()
  // 配置请求头
  const config = {
    headers: {}, // 请求头
    // timeout: 5000,
    responseType: 'arraybuffer',
    cancelToken: new axios.CancelToken(function(cancel) {
      Vue.Cancel && Vue.Cancel.push(cancel)
    })
  }

  if (token) config.headers['token'] = token
  if (version) config.headers['version'] = version

  config.method = requestMethod // 请求方式

  // 根据请求方式的传参key值进行变化
  if (requestMethod === 'get' && data || requestMethod === 'del' && data) {
    config.params = data
  } else if (requestMethod === 'post' || requestMethod === 'put') {
    config.data = data
  }

  var urlStr = window.location.href
  var urlArr = urlStr.split('#')
  var urlArrPrev = urlArr[0]
  if (erp) {
    config.url = process.env.VUE_APP_NODE_API + url // url = base url + request url
  } else if (urlArrPrev == 'http://58.246.65.68:8201/') {
    config.url = process.env.VUE_APP_SCENCE_API + url
  } else if (urlArrPrev == 'http://mes.bbelc.cn:8201/') {
    config.url = process.env.VUE_APP_MES_API + url
  } else if (urlArrPrev == 'http://mes.fortune-gas.com:8020/') {
    config.url = 'http://mes.fortune-gas.com:8020/' + url
  } else {
    config.url = process.env.VUE_APP_BASE_API + url // url = base url + request url
  }

  return new Promise((resolve, reject) => {
    axios(config)
      .then(res => {
        if (res) {
          resolve(res)
        }
      }).catch((err) => {
      resolve(false)
    })
  })
}

// get请求封装
function get(url, params, erp) {
  return request('get', url, params, erp)
}

function del(url, params) {
  return request('delete', url, params)
}

// post请求封装
function post(url, postData) {
  return request('post', url, postData)
}

// put请求封装
function put(url, postData) {
  return request('put', url, postData)
}

function exportGet(url, postData) {
  return exportRequest('get', url, postData)
}

function exportPost(url, postData) {
  return exportRequest('post', url, postData)
}

export default ({
  post,
  get,
  put,
  del,
  exportGet,
  exportPost
})
